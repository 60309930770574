<style lang="scss">
.kleine-extra {
  [class*='col-'] {
    /*justify-items: center;
    align-self: center;*/
  }
  h2 {
    text-transform: uppercase;

    margin-bottom: 30px;
    &:after {
      display: none;
    }
  }
  .color-red {
    color: $red;
  }
  .kleine-extra-box {
    border: 0px solid red;
    margin-top: 50px;
  }
  .logo-box {
    padding: 100px 0;
    text-align: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $red;
      position: absolute;
      left: 0;
      top: 49%;
      z-index: 1;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #000;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 51%;
      z-index: 1;
    }
    .img-box {
      background: #fff;
      padding: 10px 30px;
      max-width: 250px;
      display: inline-block;
      position: relative;
      z-index: 2;
      img {
        width: 100%;
        display: inline-block;

        position: relative;
        z-index: 2;
      }
    }
  }
  .btn-pdf-outer {
    .btn-pdf-link {
      display: inline-block;
      background: $red;
      padding: 10px 15px;
      text-align: center;
      color: #fff !important;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btn-pdf-outer-block {
    margin: 20px 0;
    .btn-pdf-link {
      display: block;
      border: 1px solid $red;
      padding: 20px 15px;
      text-align: center;
      font-weight: 700;
      font-size: 140%;

      span {
        display: block;
        font-size: 80%;
        font-weight: 400;
      }
      &:hover {
        background: $red;
        color: #fff !important;
      }
    }
  }
  .responsive-video {
    margin-bottom: 0;
  }
  .tabgroup-custom {
    .title {
      border-bottom: 0 !important;
      strong {
        display: inline-block;
        font-weight: 700;
        font-size: 150%;
        line-height: 50%;
        position: relative;
        top: 3px;
        margin-left: 5px;
        color: $red;
      }
    }
    .title.active {
      border-bottom: 0 !important;
      background: $red !important;
      strong {
        color: #fff;
      }
    }
    .tab-content {
      p {
        margin: 10px 0;
      }
    }
  }
  .tab-group-container {
    .title.active {
      border-bottom: 3px solid $red;
      background: #000;
      color: #fff;
      &:hover {
        background: #000;
      }
    }
    .title-container {
      @media (max-width: 768px) {
        display: block !important;
      }
    }
    .title {
      padding: 15px 20px;
      @media (max-width: 768px) {
        display: block;
        width: 100%;
        border-bottom: 3px solid #eee;
        text-align: center;
      }
    }
    .inner-content {
      [class*='col-'] {
        justify-items: center;
        align-self: center;
      }
      padding: 15px;
      img {
        display: block;
        margin: 0 auto;
      }
      .leistung {
        &:before {
          content: '';
          display: block;
          margin: 20px 0;
          width: 150px;
          height: 5px;
          background: $red;
        }
        &:after {
          content: '';
          display: block;
          margin: 20px 0;
          width: 100px;
          height: 5px;
          background: $red;
        }
        strong {
          display: block;
          font-size: 140%;
          text-transform: uppercase;
        }
        span {
          display: block;
          margin-top: 5px;
        }
        .preis {
          display: block;
          background: $red;
          color: #fff;
          padding: 10px 15px;
          display: inline-block;
          margin-top: 10px;
        }
      }
      .bsp {
        margin-top: 30px;

        .bsp-headline {
          position: relative;
          text-align: center;
          margin-bottom: 20px;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #000;
            position: absolute;
            top: 50%;
          }
          span {
            display: inline-block;
            padding: 10px 15px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 90%;
            background: #fff;
            position: relative;
            z-index: 2;
          }
        }
        a {
          display: block;

          text-align: center;
          span {
            display: block;
            padding: 10px 15px;
            background: $red;
            color: #fff;
          }

          img {
            padding: 15px;
          }
          &:hover {
            opacity: 0.7;
            transform: scale(0.95);
          }
        }
      }
    }
  }
  .werbepaket {
    .col-lg-4 {
      .werbebox {
        background: #eee;
        padding: 15px;
        width: 100%;
        @media (max-width: 992px) {
          margin-bottom: 15px;
        }
        .headline {
          font-weight: 300;
          font-size: 120%;
          text-align: left;
          strong {
            display: inline-block;
            font-size: 140%;
            text-transform: uppercase;
          }
        }
        strong {
          display: block;
          font-size: 100%;

          margin-top: 10px;
        }
        span {
          display: block;
          margin-top: 5px;
        }
        .preis {
          display: block;
          border-top: 3px solid $red;
          color: $red;
          padding: 10px 15px;
          display: inline-block;
          margin-top: 0px;
        }
        p {
          padding: 10px 0px;
        }
      }
    }
    .btn-pdf {
      margin-top: 20px;
      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px 15px;
        background: $red;
        color: #fff !important;
        position: relative;

        margin: 0 auto;
        &:hover {
          opacity: 0.7;
        }
        span {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>

<template>
  <div class="content kleine-extra container">
    <h1>KLEINE EXTRA</h1>
    <h2 class="color-red">Diese Werbung wirkt wirklich Wunder!</h2>
    <p>
      Die KLEINE EXTRA-Funktion holt aus Zeitungsinseraten erweiterte Wirklichkeiten heraus! Bis dato waren Zeitungs-Inserate von Natur aus zweidimensional. Und statisch. Stumm sowieso. Selbst, wenn man sie länger und immer wieder betrachtet hat, da änderte und rührte sich … eigentlich nichts. Wie auch? Inserate sind nun mal so. Einspruch – wir heben unsere Zeitungswerbung mit KLEINE EXTRA jetzt
      auf einen völlig neuen Level!
    </p>

    <div class="kleine-extra-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
          <div class="responsive-video">
            <video autostart="false" controls="true">
              <source src="https://assets.smd-digital.at/tarif-klz/videos/klzar_web_finale.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Was ist KLEINE EXTRA?</h3>
          <p>
            Mit <strong>KLEINE EXTRA</strong> erleben unsere ZeitungsleserInnen eine neue Dimension des Zeitungslesens. Die Kleine Zeitung wird Augmented Reality (kurz AR) – diese Technologie steckt hinter der KLEINE EXTRA-Funktion - ab sofort als Testlauf bei diversen redaktionellen Inhalten, Ihren Inseraten sowie an jedem letzten Freitag des Monats, für den Themenschwerpunkt Wohnen & Mobilität
            EXTRA einsetzen und den LeserInnen damit wirklichkeitserweiternde Aha-Erlebnisse bescheren und das, indem Sie EXTRA-Inhalte wie 3D-Modelle, Videos und vieles mehr direkt auf der Zeitungsseite und auf Ihren Inseraten entdecken können. Diese sind mit einem eigenen Symbol gekennzeichnet und warten darauf gescannt zu werden. Jetzt das Video ansehen!
          </p>
        </div>
      </div>
    </div>
    <div class="kleine-extra-box">
      <div class="row">
        <div class="col-lg-12 justify-content-center align-self-center">
          <h3>Wie kann ich KLEINE EXTRA-Inhalte konsumieren?</h3>
          <TabGroup class="tabgroup-custom">
            <Tab tabid="tab-schritt1" :title="'Schritt <strong>1</strong>'" active>
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Aktualisieren Sie die Kleine Zeitung App oder laden Sie sich die APP kostenlos im APP Store herunter</p>
                </div>
                <div class="col-lg-4 justify-content-center align-self-center">
                  <a href="https://play.google.com/store/apps/details?id=com.tailoredapps&gl=AT" target="_blank">
                    <img src="@/assets/img/img-content/digital/extra-google-play.jpg" alt="Schritt 1 - Google Play" class="img-fluid" />
                  </a>
                  <a href="https://apps.apple.com/at/app/kleine-zeitung-nachrichten-app/id393892104" target="_blank">
                    <img src="@/assets/img/img-content/digital/extra-app-store.jpg" alt="Schritt 1 - Apps Stroe" class="img-fluid" />
                  </a>
                </div>
              </div>
            </Tab>
            <Tab tabid="tab-schritt2" :title="'Schritt <strong>2</strong>'">
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Öffnen Sie die „Kleine Zeitung App “ auf Ihrem Smartphone oder Tablet und klicken Sie auf „Mehr“ (rechts unten).</p>
                </div>
                <div class="col-lg-4 justify-content-center align-self-center">
                  <img src="@/assets/img/img-content/digital/extra-schritt-zwei.jpg" alt="Schritt 2 - KLZ App öffnen" class="img-fluid" />
                </div>
              </div>
            </Tab>
            <Tab tabid="tab-schritt3" :title="'Schritt <strong>3</strong>'">
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Öffnen Sie die Scanfunktion, indem Sie auf „KLEINE EXTRA Inhalte scannen“ klicken.</p>
                  <p>Falls hier eine Abfrage für die Nutzung der Kamera auftaucht, müssen Sie diese bestätigen, um problemlos scannen zu können.</p>
                </div>
                <div class="col-lg-4 justify-content-center align-self-center">
                  <img src="@/assets/img/img-content/digital/extra-schritt-drei.jpg" alt="Schritt 3 - Scanfunktion" class="img-fluid" />
                </div>
              </div>
            </Tab>
            <Tab tabid="tab-schritt4" :title="'Schritt <strong>4</strong>'">
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Halten Sie Ihr Smartphone oder Tablet still und scannen Sie die mit dem KLEINE EXTRA Symbol gekennzeichnete Zeitungsseite in voller Größe.</p>
                </div>
                <div class="col-lg-8 justify-content-center align-self-center">
                  <div class="responsive-video">
                    <video autostart="false" controls="true">
                      <source src="https://assets.smd-digital.at/tarif-klz/videos/video-schritt-vier-anleitung.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab tabid="tab-schritt5" :title="'Schritt <strong>5</strong>'">
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Nun können Sie in die erweiterte Erlebniswelt von KLEINE EXTRA eintauchen. Trauen Sie sich ruhig auch weitere Videos und Buttons anzucklicken. Ein visuell bereicherndes Aha-Erlebnis ist garantiert!</p>
                </div>
                <div class="col-lg-4 justify-content-center align-self-center">
                  <img src="@/assets/img/img-content/digital/extra-schritt-fuenf.jpg" alt="Schritt 5 - Erlebniswelt von KLEINE EXTRA" class="img-fluid" />
                </div>
              </div>
            </Tab>
            <Tab tabid="tab-schritt6" :title="'Probleme <strong>?</strong>'">
              <div class="row inner-content justify-content-center align-self-center">
                <div class="col-lg-4 justify-content-center align-self-center">
                  <p>Probleme beim Scannen? Dann mit einem Klick Hilfeleistungen abfragen.</p>
                </div>
                <div class="col-lg-4 justify-content-center align-self-center">
                  <img src="@/assets/img/img-content/digital/extra-probleme-smartphone.jpg" alt="Probleme beim scannen?" class="img-fluid" />
                </div>
              </div>
            </Tab>
          </TabGroup>
        </div>
      </div>
    </div>
    <div class="kleine-extra-box">
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2 ">
          <h3>Wo findet man KLEINE EXTRA-Inhalte?</h3>
          <p>KLEINE EXTRA hat derzeit seinen großen Auftritt im neuen <strong>Branchenjournal „Wohnen &amp; Mobilität EXTRA'</strong>. Immer wenn bei einem Artikel oder Inserat in der Kleinen Zeitung das Kleine-Extra-Logo zu sehen ist, kommt Augmented Reality ins Spiel.</p>
          <p>Hier geht es zu unserer ersten Ausgabe des Branchenjournals. Inhalte mit dem gekennzeichnet Symbol scannen und KLEINE EXTRA selbst erleben.</p>
          <div class="btn-pdf-outer">
            <a href="../../pdf/digital/Kleine-Extra-Ausgabe2903_PDF.pdf" target="_blank" class="btn-pdf-link"><span>Hier geht es zur ersten Ausgabe</span></a>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box"><img src="@/assets/img/img-content/digital/extra-magazin.png" alt="Wo findet man KLEINE EXTRA-Inhalte?" class="img-fluid" /></div>
        </div>
      </div>
      <br /><br />
      <h2>KLEINE EXTRA Vorteile</h2>
      <ul class="content-ul">
        <li>Mit 'KLEINE EXTRA' werden die Interaktionen (Websiteaufrufe, Telefonanrufe etc.) mit Ihrer Marke oder Ihrem Produkt in Echtzeit möglich und maximiert.</li>
        <li>Ihre Marke bleibt Ihrer Zielgruppe durch die Interaktion viel stärker und länger im Gedächtnis.</li>
        <li>'KLEINE EXTRA' verbindet Print und Digital.</li>
        <li>User, die ein KLEINE EXTRA-Inserat scannen, sind immer Interessenten und zählen dadurch zur direkten Zielgruppe.</li>
      </ul>

      <div class="row justify-content-center align-self-center">
        <div class="col-lg-6 justify-content-center align-self-center">
          <br /><br />
          <div class="btn-pdf-outer-block">
            <a href="https://www.kleinezeitung.at/service/kleineextra/5948192/FAQ_Das-alles-kann" target="_blank" class="btn-pdf-link">FAQ<span>Das alles kann Augmented Reality</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="logo-box">
      <div class="img-box">
        <img src="@/assets/img/img-content/digital/extra-kleine-extra-logo.png" alt="KLEINE EXTRA Logo" class="img-fluid" />
      </div>
    </div>

    <h2>KLEINE EXTRA Pakete</h2>
    <p>Die KLEINE EXTRA Pakete können derzeit nur zusammen mit einem Print-Inserat (ab ¼ Seite) im Branchenjournal „Wohnen &amp; Mobilität EXTRA' gebucht werden.</p>
    <TabGroup class="tabgroup-custom">
      <Tab tabid="tab1-basic" :title="'Basic'" active>
        <div class="row inner-content justify-content-center align-self-center">
          <div class="col-lg-6">
            <div class="responsive-video">
              <video autostart="false" controls="true">
                <source src="https://assets.smd-digital.at/tarif-klz/videos/basic-video-paket.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="leistung">
              <strong>Leistungen</strong>
              <ul class="content-ul">
                <li>1 Video oder eine Slideshow (=Reihe von bis zu 10 Bildern, die als Filmsequenz abgespielt werden)</li>
                <li>3 Buttons (mit Verlinkung zu Landingpages, Audiofiles (z.B. Podcast Folge), PDFs (z.B. Immobilien Exposé), Social Media Seiten, Telefonnummer, E-Mail etc.) für Weblinks</li>
              </ul>
              <div class="preis">Tarif: 990,00</div>
              <ul class="content-ul">
                <li>Add-on: 3D-Walkthrough</li>
              </ul>
              <div class="preis">Tarif: 690,00</div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="tab2-standard" :title="'Standard'">
        <div class="row inner-content justify-content-center align-self-center">
          <div class="col-lg-6">
            <div class="responsive-video">
              <video autostart="false" controls="true">
                <source src="https://assets.smd-digital.at/tarif-klz/videos/KLZ_Wohnen_STANDARD_CrowndEstate.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="leistung">
              <strong>Leistungen</strong>
              <ul class="content-ul">
                <li>1 Video oder eine Bildergalerie oder eine Slideshow (=Reihe von bis zu 10 Bildern, die als Filmsequenz abgespielt werden)</li>
                <li>3 Buttons (mit Verlinkung zu Landingpages, Audiofiles (z.B. Podcast Folge), PDFs (z.B. Immobilien Exposé), Social Media Seiten, Telefonnummer, E-Mail etc.) für Weblinks</li>
                <li>1 3D-Walkthrough (360° Panorama 3D Bild mit Gyrosensor-Unterstützung)</li>
              </ul>
              <div class="preis">Tarif: 1.990,00</div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="tab3-premium" :title="'Premium'">
        <div class="row inner-content justify-content-center align-self-center">
          <div class="col-lg-6">
            <div class="responsive-video">
              <video autostart="false" controls="true">
                <source src="https://assets.smd-digital.at/tarif-klz/videos/KLZ_Mobilitaet_PREMIUM_AudietronGT.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="leistung">
              <strong>Leistungen</strong>
              <ul class="content-ul">
                <li>1 Video oder eine Bildergalerie oder eine Slideshow (=Reihe von bis zu 10 Bildern, die als Filmsequenz abgespielt werden)</li>
                <li>3 Buttons (mit Verlinkung zu Landingpages, Audiofiles (z.B. Podcast Folge), PDFs (z.B. Immobilien Exposé), Social Media Seiten, Telefonnummer, E-Mail etc.) für Weblinks</li>
                <li>1 3D-Walkthrough (360° Panorama 3D Bild mit Gyrosensor-Unterstützung)</li>
                <li>1 3D Modell mit PlaceIt-Funktion für die Raumplatzierung</li>
              </ul>
              <div class="preis">Tarif: 2.990,00</div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="tab4-mehrfachbuchung" :title="'Mehrfach-Buchungen'">
        <div class="row inner-content justify-content-center align-self-center">
          <div class="col-lg-12">
            <p>Sie wollen die KLEINE EXTRA Pakete direkt für mehrere Ausgaben buchen? Dann profitieren Sie von diesen Rabattmöglichkeiten:</p>
            <ul class="content-ul">
              <li>Keine Änderung Ihrer KLEINE EXTRA Seite bei Wiederbuchung = Tarif: 460,00 exkl. 20% USt.</li>
              <li>Änderung eines Buttons der KLEINEN EXTRA Seite = Tarif: 570,00 exkl. 20% USt.</li>
              <li>Veränderung der KLEINEN EXTRA Seite mit mehr als 1 Button = Tarif: 990,00 exkl. 20% USt.</li>
            </ul>
          </div>
        </div>
      </Tab>
    </TabGroup>

    <div class="info-txt-preis text-center">
      * Produktion von Videos und 360° Modellen im Tarif nicht enthalten.
    </div>

    <br />
    <br />
    <div class="row werbepaket">
      <div class="col-xl-12 btn-pdf">
        <a href="../../pdf/digital/0157-2101-Augmented-Reality-Flyer.pdf" target="_blank"><span>Mehr Informationen</span></a>
      </div>
    </div>
    <br />
    <br />
    <h2>KLEINE EXTRA Video-Pakete</h2>
    <p>Sie haben kein eigenes Video für Ihre KLEINE EXTRA-Seite? Dann haben wir hier etwas für Sie. Mit diesen exklusiven KLEINE EXTRA Video Paketen sind Ihre EXTRA-Inhalte komplett. Das Video verschafft den Lesern Mehrwert, fördert die Emotionalität und die Verweilzeit auf Ihren KLEINE EXTRA-Seiten. So bleibt Ihre Werbebotschaft länger im Gedächtnis.</p>
    <div class="row werbepaket">
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>CEO</strong> Video</div>
          <div class="leistung">
            <strong>Inkludierte Leistungen:</strong>
            <ul class="content-ul">
              <li>Aufzeichnung vor Ort (ein Drehort)</li>
              <li>Videolänge: 40 bis 60 Sekunden</li>
              <li>Drehzeit: 1,5 Stunden</li>
            </ul>
            <div class="preis">Tarif: 702,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>Business</strong> Video</div>
          <div class="leistung">
            <strong>Inkludierte Leistungen:</strong>
            <ul class="content-ul">
              <li>Aufzeichnung vor Ort (ein Drehort)</li>
              <li>Videolänge: 90 bis 150 Sekunden</li>
              <li>Drehzeit: 3,0 Stunden</li>
            </ul>
            <div class="preis">Tarif: 1.677,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>Premium</strong> Video</div>
          <div class="leistung">
            <strong>Inkludierte Leistungen:</strong>
            <ul class="content-ul">
              <li>Aufzeichnung vor Ort (Zwei Drehorte)</li>
              <li>Videolänge: 180 bis 300 Sekunden</li>
              <li>Drehzeit: 6,0 Stunden</li>
            </ul>
            <div class="preis">Tarif: 3.237,00</div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-center align-self-center">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">ZUSATZLEISTUNGEN</div>
            <div class="col-lg-6 text-align-right">TARIF</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">ZUSATZLEISTUNGEN</div>
            <div class="col-lg-46 col-6 text-align-left text-right-small">Transkription</div>
            <div class="col-6 text-align-left visible-small">TARIF</div>
            <div class="col-lg-6 col-6 text-align-right">149,50</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">ZUSATZLEISTUNGEN</div>
            <div class="col-lg-46 col-6 text-align-left text-right-small">Fahrtenpauschalen</div>
            <div class="col-6 text-align-left visible-small">TARIF</div>
            <div class="col-lg-6 col-6 text-align-right">0,58/km</div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-txt-preis text-center">* In einem CEO Video spricht der Geschäftsführer über das Unternehmen und laufende Projekte</div>
    <br />
    <div class="row werbepaket">
      <div class="col-xl-12 btn-pdf">
        <a href="../../pdf/digital/0157-2103-Kleine Extra-Video-Pakete.pdf" target="_blank"><span>Mehr Informationen</span></a>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro exkl. 20% Umsatzsteuer. Videopakete nur buchbar in Verbindung mit KLEINE EXTRA.</div>
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Futter',
  components: {
    Modal,
    TabGroup,
    Tab,
  },
});
</script>
